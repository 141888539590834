import { Info } from "@mui/icons-material";
import { Avatar, Button, Grid, IconButton, Popover } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";
import { useState } from "react";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import { makeStyles } from "@mui/styles";
import AIInterviewChatWindowMessageDetails from "../AIInterviewChatWindowMessageDetails";

const useStyles = makeStyles({
  loader: {
    width: "30px",
    aspectRatio: 4,
    background: `no-repeat radial-gradient(circle closest-side,#344767 90%,#0000) 0%   50%,
      no-repeat radial-gradient(circle closest-side,#344767 90%,#0000) 50%  50%,
      no-repeat radial-gradient(circle closest-side,#344767 90%,#0000) 100% 50%;`,
    backgroundSize: "calc(100%/3) 100%",
    animation: "$l7 1s infinite linear",
    marginTop: "10px",
    marginBottom: "10px",
  },
  "@keyframes l7": {
    "33%": { backgroundSize: "calc(100%/3) 0%,calc(100%/3) 100%,calc(100%/3) 100%" },
    "50%": { backgroundSize: "calc(100%/3) 100%,calc(100%/3) 0%,calc(100%/3) 100%" },
    "66%": { backgroundSize: "calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%" },
  },
});

const AIInterviewChatWindowMessage = ({
  right,
  isBot,
  message,
  isResponse,
  includeLinks,
  firstName,
  lastName,
  company,
  onPressUpdateInfo,
  onPressBackToHome,
  index,
  questionScoring,
  shouldDisableCopy,
  isLoader,
}) => {
  const avatarText = `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`.toUpperCase();
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = `message-${index}`;

  return (
    <Grid container item justifyContent={right ? "flex-end" : "flex-start"} pt={2}>
      <Grid item container maxWidth="60%" alignItems="flex-end">
        {!right && (
          <Grid display="flex">
            <Avatar
              src={
                isBot
                  ? `${getCommonBaseImageUrl(company)}/static/aiChatbotProfilePicture.png`
                  : null
              }
              alt={`${firstName} ${lastName} picture`}
              sx={{ height: "50px", width: "50px" }}
            >
              {avatarText}
            </Avatar>
          </Grid>
        )}
        <Grid
          container
          item
          justifyContent={right ? "flex-end" : "flex-start"}
          flexShrink={1}
          flexBasis={0}
          flexGrow={1}
          pl={right ? 0 : "8px"}
          pr={right ? "8px" : 0}
        >
          <MDBox
            sx={{
              backgroundColor: (theme) =>
                isResponse ? theme.palette.light.main : theme.palette.info.main,
              padding: "6px 10px 6px 10px",
              borderRadius: right ? "16px 16px 0 16px" : "16px 16px 16px 0",
              display: "inline-block",
            }}
          >
            {isLoader ? (
              <div className={styles.loader} />
            ) : (
              <MDTypography
                variant="body2"
                fontWeight="regular"
                color={isResponse ? "dark" : "white"}
                display="inline-block"
                sx={{
                  userSelect: shouldDisableCopy ? "none" : "unset",
                }}
              >
                {parse(message ?? "")}
              </MDTypography>
            )}
            {includeLinks && (
              <MDBox>
                <Button
                  component="button"
                  variant="text"
                  sx={{ padding: 0 }}
                  onClick={onPressUpdateInfo}
                >
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    color={isResponse ? "dark" : "white"}
                    display="inline-block"
                  >
                    UPDATE PROFILE
                  </MDTypography>
                </Button>
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  color={isResponse ? "dark" : "white"}
                  display="inline-block"
                  pl={1}
                  pr={1}
                >
                  |
                </MDTypography>
                <Button
                  component="button"
                  variant="text"
                  sx={{ padding: 0 }}
                  onClick={onPressBackToHome}
                >
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    color={isResponse ? "dark" : "white"}
                    display="inline-block"
                  >
                    BACK TO HOME
                  </MDTypography>
                </Button>
              </MDBox>
            )}
          </MDBox>
        </Grid>
        {right && (
          <Grid display="flex" flexDirection="column" alignItems="center">
            {questionScoring != null && (
              <>
                <IconButton color="info" onClick={handleClick}>
                  <Info />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPopover-paper": {
                      backgroundColor: "white !important",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                >
                  <AIInterviewChatWindowMessageDetails questionScoring={questionScoring} />
                </Popover>
              </>
            )}
            <Avatar
              src={
                isBot
                  ? `${getCommonBaseImageUrl(company)}/static/aiChatbotProfilePicture.png`
                  : null
              }
              alt={`${firstName} ${lastName} picture`}
              sx={{ height: "50px", width: "50px" }}
            >
              {avatarText}
            </Avatar>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

// Setting default values for the props of CalendarDayModal
AIInterviewChatWindowMessage.defaultProps = {};

// Typechecking props of the CalendarDayModal
AIInterviewChatWindowMessage.propTypes = {};

export default AIInterviewChatWindowMessage;
